import React from 'react';

import { useRouter } from 'next/router';

import useTranslation from '@hooks/useTranslation';

import Tab2avif from './tab2@1x.avif';
import Tab2png from './tab2@1x.png';
import Tab2webp from './tab2@1x.webp';
import Tab2avif2x from './tab2@2x.avif';
import Tab2png2x from './tab2@2x.png';
import Tab2webp2x from './tab2@2x.webp';

interface TabPictureProps {
    lazy?: boolean;
    fetchpriority?: boolean;
    preload?: boolean;
}

export default function TabPicture({ lazy = true, fetchpriority = false }: TabPictureProps): JSX.Element {
    const { locale } = useRouter();
    const ns = 'pages/max/design2024/components/SectionAllInOnePlatform/index';
    const { t } = useTranslation(ns);

    return (
        <picture>
            <source
                type="image/avif"
                srcSet={`${Tab2avif.src} 1x, ${Tab2avif2x.src} 2x`}
            />
            <source
                type="image/webp"
                srcSet={`${Tab2webp.src} 1x, ${Tab2webp2x.src} 2x`}
            />
            <source srcSet={`${Tab2png.src} 1x, ${Tab2png2x.src} 2x`} />
            <img
                width={Tab2png.width}
                height={Tab2png.height}
                decoding="async"
                fetchpriority={fetchpriority ? 'high' : undefined} // eslint-disable-line
                loading={lazy ? 'lazy' : undefined}
                alt={t('tab1ImageAlt')}
                src={Tab2png.src}
            />
        </picture>
    );
}
